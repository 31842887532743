/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { React } from "react";
import "./index.scss";
import { sha512 } from "js-sha512";
import { useMsal } from "@azure/msal-react";
import { DELL_URLS } from "common/constants/index";

/**
 * Renders the footer component.
 *
 * @return {JSX.Element} The rendered footer component.
 */
const Footer = () => {
  const { accounts } = useMsal();
  return (
    <>
      <div id="dashboard-layout-footer" className="footer">
        <div
          id="dashboard-footer-links"
          className="dds__container footer-container"
        >
          <div id="footer-links-rows" className="dds__row">
            <div
              id="sample-box-container"
              className="dds__col-2 dds__col--sm-3 dds__col--md-8 dds__align-left"
            >
              <div className="sample-box">
                <h3 className="dds__h3">
                  What can you do with Dell Management Portal?
                </h3>
                <p className="dds__body-2 footer-content">
                  Access BIOS passwords, get the latest versions of our IT apps,
                  and publish them instantly to stay compliant. With more
                  control over your devices, you’ll save time and hassle.
                </p>
              </div>

              <div className="dds__row dds__body-2" id="language-picker">
                <span className="dds__icon dds__icon--globe-simple footer-icon"></span>
                <p className="dds__body-2 dds__mb-0">English (US)</p>
              </div>
            </div>
            <div
              id="dell-url-container"
              className="dds__col-2 dds__col--sm-auto dds__col--md-4 "
            >
              <div id="footer-links-content" className="links dds__body-2">
                <a href={DELL_URLS.ABOUT} target="_blank" rel="noreferrer">
                  <p>About Dell</p>
                </a>
                <a href={DELL_URLS.SUPPORT} target="_blank" rel="noreferrer">
                  <p>Dell Technologies Support</p>
                </a>
                <a href={DELL_URLS.PRIVACY_US} target="_blank" rel="noreferrer">
                  <p>Services Privacy Statement</p>
                </a>
                <a href={DELL_URLS.COPYRIGHT} target="_blank" rel="noreferrer">
                  <p>© 2025 Dell Inc.</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {accounts[0] === undefined
        ? window.pendo.initialize({
            /**
             * Default visitor id for the anonymous user
             */
            visitor: {
              id: "593e0ab07d3a6e5d5bc2f153adb416dc0b19c198b36641f55c12f0c237ab9b029a8c7a8f5d448594548ba71b4609d46ecb0f747cb60fa2342fa3ec675c4a4af7",
            },
            /**
             * Default account id for the anonymous user's organization
             */
            account: {
              id: "e4536a35a927397c6f81c2a12198bc5e85a80ce2f3b796ee8804950d4390bc3f898b7530564092d88fb8aa7f6adcaade5a95c00dfa8a2151917135b22c92ab30",
            },
          })
        : window.pendo.initialize({
            visitor: {
              id: sha512(accounts[0]["username"]),
            },
            account: {
              id: sha512(accounts[0]["tenantId"]),
            },
          })}
    </>
  );
};

export default Footer;
