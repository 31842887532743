/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 - 2025 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { ActionMenu, Tabs } from "@dds/components";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { React, useEffect } from "react";
import { navRoutes } from "../SideNavDrawer/__NavRoutes__";
import { Link, useLocation, matchPath } from "react-router-dom";
import "./index.scss";
import DellWhite from "../../../images/logo-white.svg";
import preventClick from "common/utils/preventClick";

/**
 * Renders the Masthead component.
 *
 * @return {JSX.Element} The rendered Masthead - Primary header component.
 */
const Masthead = () => {
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is not authenticated and there are no accounts
    if (!isAuthenticated && (!accounts || accounts.length === 0)) {
      navigate("/");
    }
    const element = document.getElementById(
      "action-menu-trigger-container-wrapper"
    );
    new ActionMenu(element, {
      trigger: "#action-menu-trigger-button",
      placement: "bottom-end",
    });
  }, [isAuthenticated, navigate]);

  preventClick();

  //login hint should be configured in App Registration --> Token Configuration for logoutRedirect to work.
  //If Login hint is not found, then logoutRedirect would ask the user to select the account from which they need to log out.

  // Get the homeAccountId of the first account in the accounts array
  // Use the homeAccountId to get the current account from the MSAL instance
  // Get the login_hint from the idTokenClaims of the current account
  // Call the logoutRedirect method on the MSAL instance, passing in the logoutHint
  // This will log the user out and redirect them to the postLogoutRedirectUri
  const handleLogout = () => {
    const homeAccountId = accounts[0].homeAccountId;
    const currentAccount = instance.getAccountByHomeId(homeAccountId);
    const logoutHint = currentAccount.idTokenClaims.login_hint;
    instance.logoutRedirect({ logoutHint: logoutHint });

    if (window.pendo) {
      // Clear session data
      window.pendo.clearSession();
      // Clear visitor and account data
      window.pendo.updateOptions({
        visitor: null,
        account: null,
      });
    }
  };

  useEffect(() => {
    const element = document.getElementById("tab-lists-container");
    new Tabs(element);
  }, []);

  return (
    <>
      <div
        data-testid="test-id-authenticated-landing-page-header"
        id="input-text-action-header-container"
        className="dds__container dds__container--compact header__container"
      >
        <div data-testid="test-id-header-logo-text" className="dds__row">
          <div className="dds__col-2 dds__col--sm-6 dds__col--md-4 dds__d-flex">
            <div id="management-portal-container" className="dds__d-flex">
              <img src={DellWhite} alt="" className="dell-logo-auth" />
              <h5 className="dds__h5 header-title">Management Portal</h5>
            </div>
          </div>
          <div
            id="input-text-action-wrapper"
            className="dds__col-2 dds__col--sm-6 dds__col--md-8 dds__d-flex dds__justify-content-end"
          >
            <div
              id="text input-text-action-container"
              className="dds__input-text__container"
            >
              <div className="dds__input-text__wrapper"></div>
            </div>
            <button
              className="button dds__button dds__button--editorial-light"
              id="action-menu-trigger-button"
              type="button"
            >
              <div className="loggedin-user">
                <div className="dds__icon dds__icon--user profile-image"></div>
                <p className="dds__body-2 dds__d-flex username">
                  {" "}
                  {accounts && accounts[0] ? accounts[0].name : ""}
                </p>
                <div className="dds__d-flex resize-icon--chevron-down">
                  <div className="dds__icon dds__icon--chevron-down profile-image-second"></div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div data-testid="test-id-header-tab-links" className="dds__row">
          <div className="dds__col-2 dds__col--sm-6 dds__col--md-12">
            <div
              id="tab-lists-container"
              className="dds__tabs routes"
              data-dds="tabs"
            >
              <div className="dds__tabs__list-container">
                <ul
                  id="tab-lists"
                  className="dds__tabs__list dds__tabs__list--overflow"
                  role="tablist"
                  aria-orientation="horizontal"
                >
                  {navRoutes.map((route, index) => {
                    const isActive =
                      !!matchPath(
                        {
                          path: route.link,
                          end: false,
                        },
                        location.pathname
                      ) || false;
                    return (
                      <li role="none" key={route.id}>
                        <Link
                          to={route.link}
                          target={route.external && "_blank"}
                          id={`tab-${route.id}`}
                          className={`dds__tabs__tab link${
                            isActive ? " active__link" : ""
                          }`}
                          role="tab"
                          aria-controls={`tab-basic-${index}-pane`}
                          aria-selected={isActive ? "true" : "false"}
                          tabIndex={0}
                        >
                          <span
                            className="dds__tabs__tab__label dds__align-middle"
                            title={route.title}
                          >
                            {route.title}
                            {route.external && (
                              <span className="dds__icon dds__icon--pop-up-arrow-corner link__icon custom-icon-pop-up-arrow-corner "></span>
                            )}
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Profile menu */}
        <div
          data-testid="test-id-header-profile-menu"
          className="dds__action-menu"
          data-dds="action-menu"
          data-trigger="#action-menu-trigger-button"
          id="action-menu-trigger-container-wrapper"
        >
          <div
            id="profile-menu-trigger-container"
            className="dds__action-menu__container"
            tabIndex="-1"
            role="presentation"
            aria-hidden="true"
          >
            <ul className="dds__action-menu__menu" role="menu" tabIndex="-1">
              <li role="presentation">
                <ul role="group" aria-label="Profile name">
                  <li className="dds__action-menu__item" role="none">
                    <button
                      id="btn-profile-logout"
                      type="button"
                      role="menuitem"
                      tabIndex="-1"
                      onClick={handleLogout}
                    >
                      <div className="dds__icon dds__action-menu__icon dds__icon--log-out"></div>
                      <span>Log out</span>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Masthead;
